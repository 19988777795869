import merge from 'lodash/merge'

const commonConfig = {
  auth: {
    authorizationPath: '/auth/oauth/v2/authorize',
    logoutPath: '/login/responses/logoff.html',
    popupOptions: { width: 482, height: 680 },
    redirectUri: `${window.location.origin}/auth/login`,
    responseType: 'token id_token',
    scope: ['openid profile'],
    storageType: 'localStorage',
    tokenType: 'Bearer',
  },
}

const envConfigs = {
  qa: {
    auth: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost:
        'https://logonservices.iam.perf.target.com/login/responses/logoff.html?target=' + `${window.location.origin}`,
      clientId: 'wfm_tm_enablement_ui_npe_im',
      authorizationUrl: 'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
      nonce: '1234',
    },
    accruals: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8080/wfm_accruals/v1',
    },
    schedule: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8090/wfm_schedules/v1',
    },
    device: {
      key: 'c907309631c5a0fd2d859305cc5df8bca3bbd77e',
      url: 'https://stage-api.target.com/wfm_device_registrations/v1',
    },
    pnAck: {
      key: 'ea1fe0099e2451e813a4deb52a53f8dc9760d9be',
      url: 'https://stage-api.target.com/wfm_acknowledgements/v1',
    },
    availability: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8080/wfm_availabilities/v1',
    },
    userAccess: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8080/wfm_user_access/v1',
      urlv2: 'http://localhost:8080/wfm_user_access/v2',
    },
    userProfiles: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8080/wfm_user_profiles/v1',
      urlv2: 'http://localhost:8080/wfm_user_profiles/v2',
    },
    configuration: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8081/wfm_configurations/v1',
      urlv2: 'http://localhost:8081/wfm_configurations/v2',
    },
    mandatory: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8071/wfm_mandatory_schedules/v1',
    },
    availableShifts: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://wfmavailableshifts.dev.target.com/wfm_available_shifts/v1/available_shifts',
    },
    coverAvailableShift: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://wfmavailableshifts.dev.target.com/wfm_available_shifts/v1/available_shifts/assignment',
    },
    giveUpShift: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://wfmavailableshifts.dev.target.com/wfm_available_shifts/v1/team_member_available_shifts',
    },
    tmAvailableShifts: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8080/wfm_available_shifts/v1',
    },
    timecards: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8080/wfm_time_cards/v1',
    },
    worker: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8090/wfm_workers/v1',
      appleUrl: 'https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/freeProductCodeWizard?code=',
    },
    location: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8092/wfm_locations/v1',
    },
    timeOff: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8080/wfm_time_off_requests/v1',
    },
    storeAvailability: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://workerscheduleavailabilityservice.dev.target.com/wfm_worker_schedule_availabilities/v1',
    },
    punchCorrection: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://localhost:8080/wfm_punch_correction_requests/v1',
    },
    constraints: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8080/wfm_constraints/v1',
    },
    feedback: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:8080/wfm_user_feedback/v1',
    },
  },
  dev: {
    auth: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost:
        'https://logonservices.iam.perf.target.com/login/responses/logoff.html?target=' + `${window.location.origin}`,
      clientId: 'wfm_tm_enablement_ui_npe_im',
      authorizationUrl: 'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
      nonce: '1234',
    },
    accruals: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://accrualsapi.dev.target.com/wfm_accruals/v1',
    },
    schedule: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://scheduleaggregatorservice-dev.dev.target.com/wfm_schedules/v1',
    },
    device: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://deviceregistrationservice.dev.target.com/wfm_device_registrations/v1',
    },
    pnAck: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://mytimepnackext.dev.target.com/wfm_acknowledgements/v1',
    },
    availability: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://wfmavailability-dev.dev.target.com/wfm_availabilities/v1',
    },
    userAccess: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://useraccessservice.dev.target.com/wfm_user_access/v1',
      urlv2: 'https://useraccessservice.dev.target.com/wfm_user_access/v2',
    },
    userProfiles: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://userprofileservice-dev.dev.target.com/wfm_user_profiles/v1',
      urlv2: 'https://userprofileservice-dev.dev.target.com/wfm_user_profiles/v2',
    },
    configuration: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://configurationservice.dev.target.com/wfm_configurations/v1',
      urlv2: 'https://configurationservice.dev.target.com/wfm_configurations/v2',
    },
    mandatory: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://wfmmandatory.dev.target.com/wfm_mandatory_schedules/v1',
    },
    availableShifts: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://wfmavailableshifts.dev.target.com/wfm_available_shifts/v1',
    },
    timecards: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://timecardaggregator.dev.target.com/wfm_time_cards/v1',
    },
    worker: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://wfmworker.dev.target.com/wfm_workers/v1',
      appleUrl: 'https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/freeProductCodeWizard?code=',
    },
    location: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_locations/v1',
    },
    timeOff: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://wfmtimeoff.dev.target.com/wfm_time_off_requests/v1',
    },
    shiftswap: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://shiftswapapi.dev.target.com/wfm_shift_swaps/v1',
    },
    storeAvailability: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://workerscheduleavailabilityservice.dev.target.com/wfm_worker_schedule_availabilities/v1',
    },
    viewAvailabilityTemp: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'http://localhost:3000/data/availability_75341586.json',
    },
    punchCorrection: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://punchcorrectionapi.dev.target.com/wfm_punch_correction_requests/v1',
    },
    constraints: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_constraints/v1',
    },
    feedback: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://userfeedbackapi.dev.target.com/wfm_user_feedback/v1',
    },
  },
  uat: {
    auth: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost:
        'https://logonservices.iam.perf.target.com/login/responses/logoff.html?target=' + `${window.location.origin}`,
      clientId: 'wfm_tm_enablement_ui_npe_im',
      authorizationUrl: 'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
      nonce: '1234',
    },
    accruals: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_accruals/v1',
    },
    schedule: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_schedules/v1',
    },
    device: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_device_registrations/v1',
    },
    pnAck: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_acknowledgements/v1',
    },
    availability: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_availabilities/v1',
    },
    userAccess: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_user_access/v1',
      urlv2: 'https://stage-api.target.com/wfm_user_access/v2',
    },
    userProfiles: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_user_profiles/v1',
      urlv2: 'https://stage-api.target.com/wfm_user_profiles/v2',
    },
    configuration: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_configurations/v1',
      urlv2: 'https://stage-api.target.com/wfm_configurations/v2',
    },
    mandatory: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_mandatory_schedules/v1',
    },
    availableShifts: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_available_shifts/v1',
    },
    timecards: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_time_cards/v1',
    },
    worker: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_workers/v1',
      appleUrl: 'https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/freeProductCodeWizard?code=',
    },
    location: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_locations/v1',
    },
    timeOff: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_time_off_requests/v1',
    },
    shiftswap: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_shift_swaps/v1',
    },
    storeAvailability: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_worker_schedule_availabilities/v1',
    },
    punchCorrection: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_punch_correction_requests/v1',
    },
    constraints: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_constraints/v1',
    },
    feedback: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_user_feedback/v1',
    },
  },
  support: {
    auth: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost:
        'https://logonservices.iam.perf.target.com/login/responses/logoff.html?target=' + `${window.location.origin}`,
      clientId: 'wfm_tm_enablement_ui_npe_im',
      authorizationUrl: 'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
      nonce: '1234',
    },
    accruals: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://accrualsapi.dev.target.com/wfm_accruals/v1',
    },
    schedule: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://scheduleaggregatorservice-poc.dev.target.com/wfm_schedules/v1',
    },
    device: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://deviceregistrationservice-poc.dev.target.com/wfm_device_registrations/v1',
    },
    pnAck: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://mytimepnackext-poc.dev.target.com/wfm_acknowledgements/v1',
    },
    availability: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://wfmavailability-poc.dev.target.com/wfm_availabilities/v1',
    },
    userAccess: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://useraccessservice-poc.dev.target.com/wfm_user_access/v1',
      urlv2: 'https://useraccessservice-poc.dev.target.com/wfm_user_access/v2',
    },
    userProfiles: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://userprofileservice-poc.dev.target.com/wfm_user_profiles/v1',
      urlv2: 'https://userprofileservice-poc.dev.target.com/wfm_user_profiles/v2',
    },
    configuration: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://configurationservice-poc.dev.target.com/wfm_configurations/v1',
      urlv2: 'https://configurationservice-poc.dev.target.com/wfm_configurations/v2',
    },
    mandatory: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://wfmmandatory-poc.dev.target.com/wfm_mandatory_schedules/v1',
    },
    availableShifts: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://wfmavailableshifts-poc.dev.target.com/wfm_available_shifts/v1',
    },
    worker: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://wfmworker-poc.dev.target.com/wfm_workers/v1',
      appleUrl: 'https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/freeProductCodeWizard?code=',
    },
    location: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_locations/v1',
    },
    timeOff: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://timeoff-poc.dev.target.com/wfm_time_off_requests/v1',
    },
    shiftswap: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://shiftswap-poc.dev.target.com/wfm_shift_swaps/v1',
    },
    feedback: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://wfmfeedbackapi.dev.target.com/wfm_user_feedback/v1',
    },
  },
  stg: {
    auth: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost:
        'https://logonservices.iam.perf.target.com/login/responses/logoff.html?target=' + `${window.location.origin}`,
      clientId: 'wfm_tm_enablement_ui_npe_im',
      authorizationUrl: 'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
      nonce: '1234',
    },
    accruals: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_accruals/v1',
    },
    schedule: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_schedules/v1',
    },
    device: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_device_registrations/v1',
    },
    pnAck: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_acknowledgements/v1',
    },
    availability: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_availabilities/v1',
    },
    userAccess: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_user_access/v1',
      urlv2: 'https://stage-api.target.com/wfm_user_access/v2',
    },
    userProfiles: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_user_profiles/v1',
      urlv2: 'https://stage-api.target.com/wfm_user_profiles/v2',
    },
    configuration: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_configurations/v1',
      urlv2: 'https://stage-api.target.com/wfm_configurations/v2',
    },
    mandatory: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_mandatory_schedules/v1',
    },
    availableShifts: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_available_shifts/v1',
    },
    worker: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_workers/v1',
      appleUrl: 'https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/freeProductCodeWizard?code=',
    },
    location: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_locations/v1',
    },
    timecards: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_time_cards/v1',
    },
    timeOff: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_time_off_requests/v1',
    },
    shiftswap: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_shift_swaps/v1',
    },
    storeAvailability: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_worker_schedule_availabilities/v1',
    },
    punchCorrection: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_punch_correction_requests/v1',
    },
    constraints: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_constraints/v1',
    },
    feedback: {
      key: 'b9ef4b57f852f9a3c886bd8b0da5447c23eacb65',
      url: 'https://stage-api.target.com/wfm_user_feedback/v1',
    },
  },
  prod: {
    auth: {
      host: 'https://oauth.iam.target.com',
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutHost:
        'https://logonservices.iam.target.com/login/responses/logoff.html?target=' + `${window.location.origin}`,
      clientId: 'wfm_tm_enablement_ui_prod_im',
      nonce: '1234',
    },
    accruals: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_accruals/v1',
    },
    schedule: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_schedules/v1',
    },
    device: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_device_registrations/v1',
    },
    pnAck: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_acknowledgements/v1',
    },
    availability: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_availabilities/v1',
    },
    userAccess: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_user_access/v1',
      urlv2: 'https://api.target.com/wfm_user_access/v2',
    },
    userProfiles: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_user_profiles/v1',
      urlv2: 'https://api.target.com/wfm_user_profiles/v2',
    },
    configuration: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_configurations/v1',
      urlv2: 'https://api.target.com/wfm_configurations/v2',
    },
    mandatory: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_mandatory_schedules/v1',
    },
    availableShifts: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_available_shifts/v1',
    },
    worker: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_workers/v1',
      appleUrl: 'https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/freeProductCodeWizard?code=',
    },
    location: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_locations/v1',
    },
    timecards: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_time_cards/v1',
    },
    timeOff: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_time_off_requests/v1',
    },
    shiftswap: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_shift_swaps/v1',
    },
    storeAvailability: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_worker_schedule_availabilities/v1',
    },
    punchCorrection: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_punch_correction_requests/v1',
    },
    constraints: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_constraints/v1',
    },
    feedback: {
      key: 'd4465835234778cb3c58aeded4b489b306841a9f',
      url: 'https://api.target.com/wfm_user_feedback/v1',
    },
  },
}

// env.js sets APP_ENV
const appEnv = process.env.REACT_APP_ENV
const config = envConfigs[appEnv]
const apiConfig = merge(commonConfig, config)

export default apiConfig
