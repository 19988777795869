import { CircularProgress, Grid, Skeleton, Typography } from '@mui/material'
import { IError } from '../../../../api/globalResponses'
import { ERROR_CODES_SHOW_MESSAGE } from '../../../../constants/errorConstants'
import { JSX } from 'react'

type TVariant = 'placeholder' | 'default' | 'noData' | 'error' | 'spinner'

interface TTableFallback {
  children?: React.ReactNode // custom component
  variant?: TVariant
  error?: IError
}

export default function TableFallback({ children, variant = 'default', error }: TTableFallback) {
  return (
    <Grid container item justifyContent={'center'} marginTop={'10px'}>
      {children}
      {variant ? <Fallback variant={variant} error={error} /> : <></>}
    </Grid>
  )
}

const Fallback = ({ variant, error }: { variant: TVariant; error?: IError }): JSX.Element => {
  switch (variant) {
    case 'placeholder':
      return (
        <Skeleton
          sx={{
            marginX: '10px',
          }}
          height={50}
          width={'auto'}
        />
      )
    case 'spinner':
      return <CircularProgress size={40} />
    case 'default':
      return (
        <Typography component="h2" variant="subtitle2">
          Please select a location, category, start date and end date.
        </Typography>
      )
    case 'noData':
      return (
        <Typography component="h2" variant="subtitle2">
          No records found
        </Typography>
      )
    case 'error':
      return (
        <Typography data-cy={'myteam_mobile_error_message'} sx={{ color: 'primary.main' }}>
          {error && ERROR_CODES_SHOW_MESSAGE.includes(error?.code)
            ? error.message
            : 'A error occured, please try again'}
        </Typography>
      )
    default:
      return <></>
  }
}
